@charset 'UTF-8';

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #fff;
}
@font-face {
  font-family: slick;
  font-weight: 400;
  font-style: normal;
  src: url(slick.eot);
  src: url(slick.eot?#iefix) format("embedded-opentype"),
    url(slick.woff) format("woff"), url(slick.ttf) format("truetype"),
    url(slick.svg#slick) format("svg");
  font-display: swap;
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: blue;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  top: 92%;
  z-index: 11;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: blue;
}
.slick-prev {
  left: 120px;
}
[dir="rtl"] .slick-prev {
  left: 120px;
  left: auto;
}
.slick-prev:before {
  content: url("/images/VLSI/slider-prev-arrow.svg");
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: url("/images/VLSI/slider-next-arrow.svg");
}
.slick-next {
  left: 200px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 120px;
}
[dir="rtl"] .slick-next:before {
  content: url("/images/VLSI/slider-prev-arrow.png");
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  bottom: 30px;
  width: 15%;
  margin: 0;
  list-style: none;
  text-align: center;
  left: 80%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #fff;
  border: 0;
  outline: 0;
  background: #fff;
  border-radius: 10px;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #fff;
}
.slick-dots li.slick-active {
  width: 55px;
  background-color: #fff;
  color: #fff;
  border-radius: 10px;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fff;
  width: 55px;
} /*# sourceMappingURL=slick-theme.min.css.map */
.slideWrapperContainer .slick-slider {
  @apply flex flex-row;
}
.slick-slider .prevArrow {
  @apply w-1/12 self-center cursor-pointer text-left;
}
.slick-slider .nextArrow {
  @apply w-1/12 self-center cursor-pointer text-right;
}
.slick-list {
  @apply w-full;
}
.slick-track {
  @apply flex flex-wrap;
}

/* responsive */

@media screen and (min-width: 300px) {
  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    display: none;
  }
  .slick-prev:before,
  [dir="rtl"] .slick-next:before {
    display: none;
  }
  .slick-dots {
    bottom: 30px;
    left: 30%;
    width: 100px;
  }
}
@media screen and (min-width: 360px) {
  .slick-prev:before {
    content: url("/images/VLSI/mobile-slider-prev-arrow.svg");
    display: block;
  }
  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: url("/images/VLSI/mobile-slider-next-arrow.svg");
    display: block;
  }
  .slick-dots {
    bottom: 30px;
    width: 50%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-prev {
    left: 30px;
  }
  .slick-next {
    left: 87%;
  }
  .slick-dots,
  .slick-next,
  .slick-prev {
    top: 80%;
  }
  .slick-dots {
    top: 75%;
    left: 30%;
  }
  .slick-dots li button {
    font-size: 6px;
    width: 6px;
    height: 6px;
  }
  .slick-dots li button:before {
    font-size: 6px;
    width: 6px;
    height: 6px;
    top: -5px;
  }
  .slick-dots li.slick-active {
    width: 55px;
    height: 8px;
    border-radius: 5px;
  }
}
@media screen and (min-width: 640px) {
  .slick-prev:before {
    content: url("/images/VLSI/mobile-slider-prev-arrow.svg");
  }
  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: url("/images/VLSI/mobile-slider-next-arrow.svg");
  }
  .slick-dots {
    bottom: 30px;
    width: 35%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-prev {
    left: 30px;
  }
  .slick-next {
    left: 93%;
  }
  .slick-dots,
  .slick-next,
  .slick-prev {
    top: 80%;
  }
  .slick-dots {
    top: 75%;
    left: 30%;
  }
  .slick-dots li button {
    font-size: 6px;
    width: 10px;
    height: 10px;
  }
  .slick-dots li button:before {
    font-size: 6px;
    width: 10px;
    height: 10px;
    top: -5px;
  }
  .slick-dots li.slick-active {
    width: 55px;
    height: 10px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 768px) {
  .slick-prev:before {
    content: url("/images/VLSI/slider-prev-arrow.svg");
  }
  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: url("/images/VLSI/slider-next-arrow.svg");
  }
  .slick-next {
    left: 200px;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: 120px;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: blue;
    border: none;
    outline: 0;
    background: 0 0;
    position: absolute;
    top: 92%;
    z-index: 11;
  }
  .slick-prev {
    left: 120px;
  }
  [dir="rtl"] .slick-prev {
    left: 120px;
    left: auto;
  }
  .slick-next {
    left: 200px;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: 120px;
  }

  .slick-dots {
    bottom: 30px;
    width: 15%;
    margin: 0;
    list-style: none;
    text-align: center;
    left: 80%;
    top: 90%;
  }

  @media screen and (min-width: 770px) {
    .slick-dots {
      bottom: 30px;
      width: 30%;
      margin: 0;
      list-style: none;
      text-align: center;
      left: 73%;
      top: 85%;
    }
    .slick-next,
    .slick-prev {
      top: 85%;
    }
    .slick-prev {
      left: 30px;
    }
    [dir="rtl"] .slick-prev {
      left: 30px;
      left: auto;
    }
    .slick-next {
      left: 100px;
    }
    [dir="rtl"] .slick-next {
      right: auto;
      left: 100px;
    }
    .slick-prev:before {
      content: url("/images/VLSI/tab-slider-left-arrow.svg");
    }
    .slick-next:before,
    [dir="rtl"] .slick-prev:before {
      content: url("/images/VLSI/tab-slider-right-arrow.svg");
    }
    .div-position {
      top: 30%;
    }
  }
  @media screen and (min-width: 1360px) {
    #big-slider {
      display: block;
    }
    #small-slider {
      display: none;
    }
    .slick-prev:before {
      content: url("/images/VLSI/slider-prev-arrow.svg");
    }
    .slick-next:before,
    [dir="rtl"] .slick-prev:before {
      content: url("/images/VLSI/slider-next-arrow.svg");
    }
    .slick-prev {
      left: 120px;
    }
    [dir="rtl"] .slick-prev {
      left: 120px;
      left: auto;
    }
    .slick-next {
      left: 200px;
    }
    [dir="rtl"] .slick-next {
      right: auto;
      left: 120px;
    }
    .slick-dots li button {
      font-size: 6px;
      width: 16px;
      height: 16px;
    }
    .slick-dots li button:before {
      font-size: 6px;
      width: 16px;
      height: 16px;
      top: -5px;
    }
    .slick-dots li.slick-active {
      width: 69px;
      height: 16px;
      border-radius: 10px;
    }
    .slick-dots {
      bottom: 30px;
      width: 30%;
      margin: 0;
      list-style: none;
      text-align: center;
      left: 73%;
      top: 92%;
    }
    .slick-next,
    .slick-prev {
      top: 92%;
    }
  }
}
